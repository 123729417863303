import React from 'react'
// import { mainblog } from './BlogData'

function BlogItems(props) {
  // const data = mainblog
  return (
    
    <ul className="blog-item-container">
    {props.data.map((e) => 
                <div className="blog-article">
                <li className="blog-item">
                    <img className="blog-img" alt="123" src={ e.image } />
                    <div className="blog-text-container">
                    <h1 className="blog-item-title">
                        {e.title}
                    </h1>
                    <div className="blog-item-detail">
                    <h2 className="blog-item-category">
                        {e.header}
                    </h2>
                    <h2 className="blog-item-date">
                       | {e.date}
                    </h2>
                    </div>
                    <p className="blog-para-item">
                        {/* {e.desc} */}
                    </p>
                    </div>
                </li>
                </div>
      )}
      </ul>

    
  )
}

export default BlogItems