import React from 'react'
import { whychild, whyusitem } from '../../../data/data'
import  WhyUsItem  from './WhyUsItem'

function WhyUs() {
  return (
    <section id="why-us" >
      <WhyUsItem
      item = { whyusitem }
      data = { whychild }
      />
    </section>
  )
}

export default WhyUs