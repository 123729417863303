import React from 'react'
// import { FaTools } from 'react-icons/fa';
// import { RiSecurePaymentFill } from 'react-icons/ri';
// import { BiTimer } from 'react-icons/bi';
import { whyitem, whyitems } from '../../../data/data';

function WeDifferent() {
    const e = whyitem
    
    
    const data = whyitems
    
    return (
    <div className="why-us">
        <div className="why-us-container">
            <ul className="why-us-items">
                <li className="why-us-item our-company">
                    <div className="why-us-hover">
                        <div className="choose-border">
                    <h2 className="why-choose">
                        {e.title}
                    </h2>
                        </div>
                    <h3 className="why-company-title">
                        {e.header}
                    </h3>
                    </div>
                        <hr />
                        <p className="why-company why-left">
                        {e.para}
                    </p>
                </li>
                {data.map((o) => (
                <li className={o.li}>
                <div className="why-us-hover">
                
                    <h1 className="why-us-number">
                        {o.icon}
                    </h1>
                    
                    <h3 className="why-title">
                    {o.title}
                    </h3>
                </div>
                    <p className="why-company">
                        {o.para}
                    </p>
                </li>
                ))}
            </ul>
        </div>
    </div>
  )
}

export default WeDifferent