import React from 'react'


function Logo(props) {
  return (
    <div id="sp-logo" className="col-auto">
            <div className="sp-column">
              <div className="logo">
                <a href="/">
                <img className="logo-image" src={props.images.logo} alt="logo" />
                </a>
              </div>
            </div>
          </div>

  )
}

export default Logo