import './App.css';
import Home from './components/pages/Home/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/pages/Footer/Footer';
import CompanyPage from './components/pages/Home/CompanyPage';
import ServicePage from './components/pages/Home/ServicePage';
import ProjectPage from './components/pages/Home/ProjectPage';
import BlogPage from './components/pages/Home/BlogPage';
import ContactPage from './components/pages/Home/ContactPage';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

function App() {
  return (
    <div className="App">
  <Router>
      <Header />
        <Routes>
          <Route path="/" element={ <Home />} />
        </Routes>
        <Routes>
          <Route path="/company"
          element={ <CompanyPage/>} />
        </Routes>
        <Routes>
          <Route path="/services" 
            element={<ServicePage />} />
        </Routes>
        <Routes>
          <Route path="/projects"
          element={ <ProjectPage/>}/>
        </Routes>
        <Routes>
          <Route path="/blog"
          element={ <BlogPage/>} />
        </Routes>
        <Routes>
          <Route path="/contact"
          element={<ContactPage/>} />   
        </Routes>
    <Footer />
  </Router>
  {/* <MessengerCustomerChat /> */}
    </div>
  );
}

export default App;
