import React from 'react'
// import { BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { home } from '../../../data/data';
import About from '../About/About';
import Company from '../Company/Company';
import Services from '../Services/Services';
import Projects from '../Projects/Projects';
import WhyUs from '../WhyUs/WhyUs';
import Tech from '../Tech/Tech';
import Testimonials from '../Testimonials/Testimonials';
import { testimonialData } from '../Testimonials/TestimonialData';
import Blog from '../Blog/Blog';
// import About from './Home/About';
// import Company from './Home/company/Company';
// import { home }  from '../../data/data';
// import Services from './Home/Services/Services';
// import Projects from './Home/projects/Projects';
// import WhyUs from './Home/whyus/WhyUs';
// import Tech from './Home/technology/Tech';
// import Testimonials from './Home/Testimonials/Testimonials';
// import { testimonialData } from './Home/Testimonials/TestimonialData';
// import Blog from './Home/Blog/Blog';

function Home() {

//   useEffect(() => {
//     window.scroll(0,0)
//   }, [])

  return (
    <div>
      <About data = { home }/>
            <Company />
            <Services />  
            <Projects />
             <WhyUs />  {/* // check filed  */}
            <Tech />  {/* // check filed  */}
            <Testimonials 
            data = { testimonialData  }
            />
            <Blog />
            <ScrollToTop className='scoll-to-top' smooth/>
    </div>
  )
}

export default Home