import React from 'react'
import { blogheader, mainblog } from './BlogData'
import BlogHeader from './BlogHeader'
import BlogItems from './BlogItems'

function Blog() {
  return (
    <section id="Blog">
    <div className="blog-container">
        <BlogHeader 
        data = { blogheader }/>
        <div className="blog-items">
            <div className="blog-items-container">
                <BlogItems 
                data = { mainblog }
                />
            </div>
        </div>
    </div>
</section>
  )
}

export default Blog