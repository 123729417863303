import React, { useState, useRef } from 'react'
// import FileUpload from "./FileUpload/FileUpload.jsx"
// import FileList from './FileList/FileList.jsx';
import './FileUpload/FileUpload.scss'
import { FaFileUpload } from 'react-icons/fa'
// import emailjs from 'emailjs-com';





 

export default function ContactForm()  {
    // const form = useRef();
    // const sendEmail = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm(
    //         process.env.REACT_APP_SERVICE_ID,
    //         process.env.REACT_APP_TEMPLATE_ID,
    //         form.current ,
    //         process.env.REACT_APP_PUBLIC_KEY), this
    //     .then((result) => {
    //         console.log(result.text);
    //         alert("Email Send")
    //     }, (error) => {
    //         console.log(error.text);
    //         alert(JSON.stringify(err));
    //     });
    //     e.target.reset()
    // }
    const [showhide, setShowhide]=useState('hide');
    const handleshowhide=(event)=>{
        const getuser = event.target.value;    
        setShowhide(getuser)
   
      }

    // const [formData, setFormData ] = React.useState(
    //     {
    //         Name:"",
    //         email:"",
    //         telephone:"",
    //         message:"",
    //     }
    // )

    // function handleChange(event) {
    //     const {name, value, type, checked} = event.target
    //     setFormData(prevFormData => {
    //         return {
    //             ...prevFormData,
    //             [name]: type === "checkbox" ? checked : value
    //         }
    //     })
    // }
    
    // const [files, setFiles] = useState([])

    // const removeFile = (filename) => {
    //     setFiles(files.filter(file => file.name !== filename))
    // }
    

    const form = useRef();

    // const sendEmail = (e) => {
    //   e.preventDefault();
  
    //   emailjs.sendForm(
    //     process.env.REACT_APP_SERVICE_ID,
    //     process.env.REACT_APP_TEMPLATE_ID, 
    //     e.target,
    //     process.env.REACT_APP_USER_ID)
    //     .then((result) => {
    //         console.log(result.text);
    //     }, (error) => {
    //         console.log(error.text);
    //     });
    // };

//   const sendEmail = (e) => {
//         e.preventDefault();

//         emailjs.sendForm(     // eslint-disable-line
//             process.env.REACT_APP_SERVICE_ID,
//             process.env.REACT_APP_TEMPLATE_ID,
//             form.current ,
//             process.env.REACT_APP_PUBLIC_KEY), this
//         .then((result) => {
//             console.log(result.text);
//             alert("Email Send")
//         }
//         , (error) => {
//             console.log(error.text);
//             alert(JSON.stringify(error));
//         }
//         );
//         e.target.reset()
//     }



  return (
    <div className="contact_form"   >
        <div className="contact_form_container">
            <div className="section_title">
                <h2 className="form_title_heading">
                    <div className="company_page-header">
                        <h2 className="company_pg-hd-title">
                            ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΊΑΣ
                        </h2>
                    </div>
                </h2>
                        <hr />
                </div>
                <form 
                    id="form"
                    className="contact_form-row"
                    ref={form}
                    method="post"
                    action="/sendemail"
                    encType="multipart/form-data" >
                    <div className="contact_form-columns">
                        <div className="form-group-col">
                            <input 
                            // value={formData.Name}
                            // onChange={handleChange}
                            type="text"
                            for="name" 
                            name="name" 
                            className="form-control" 
                            placeholder="Όνομα" 
                            required
                            />
                        </div>
                        
                        <div className="form-group-col">
                            <input 
                            // value={formData.email}
                            // onChange={handleChange}
                            type="email"
                            for="email" 
                            name="email" 
                            className="form-control" 
                            placeholder="Email" 
                            required
                            /> 
                        </div>
                        <div className="form-group-col">
                            <input 
                            // value={formData.telephone}
                            // onChange={handleChange}
                            type="telephone"
                            for="telephone"
                            name="telephone" 
                            className="form-control" 
                            placeholder="Τηλέφωνο" 
                            required
                             />
                        </div>
                        <div className="form-group-col">
                            <select className="select-items" name="categories" id="categories" from="categories" onChange={(e)=>(handleshowhide(e))}>
                                <option className="select-item" value="hide" selected disabled>  - - -  ΕΠΙΛΕΞΤΕ ΚΑΤΗΓΟΡΙΑ  - - -  </option>
                                <option for="categories" className="select-item" value="carwash">ΠΛΥΣΙΜΟ ΜΗΧΑΝΙΜΑΤΩΝ ΕΡΓΟΥ</option>
                                <option for="categories" className="select-item" value="blockages">ΑΠΟΦΡΑΞΕΙΣ</option>
                                <option for="categories" className="select-item" value="treeprunning">ΚΛΑΔΕΜΑ ΔΕΝΤΡΩΝ</option>
                                <option for="categories" className="select-item" value="vehicleservice">ΣΥΝΤΗΡΗΣΗ ΜΗΧΑΝΙΜΑΤΩΝ ΕΡΓΟΥ</option>
                                <option for="categories" className="select-item" value="snowclean">ΑΠΟΧΙΟΝΙΣΜΟΣ ΔΡΟΜΩΝ</option>
                                {/* <option for="categories" className="select-item" id="select" value="sendcv">ΑΠΟΣΤΟΛΗ ΒΙΟΓΡΑΦΙΚΟΥ</option> */}
                                <option for="categories" className="select-item" value="other">ΑΛΛΟ</option>
                            </select>
                        </div>
                        {
                            showhide==='sendcv' && (
                                <>
                                <div className="file-card">
                    
                                    <div className="file-inputs">
                                        <input type="file" 
                                        name="image" 
                                        required 
                                        // onChange={uploadHandler} 
                                        />
                                        <button>
                                            <i>
                                                <FaFileUpload/>
                                            </i>
                                            Upload
                                        </button>
                                    </div>
                    
                                    <p className="main">Supported files</p>
                                    <p className="info">PDF, JPG, PNG</p>
                    
                                </div>
                            </>        
                            // <div 
                            // for="attachment"
                            // className="fileUpload" 
                            // className="file-card"
                            // value="hide" >
                            //     <input
                            //      type="file"
                            //      required
                            //      className="file-inputs"  />
                            /* <FileUpload 
                            files={files} 
                            setFiles={setFiles} 
                            removeFile={removeFile} />
                            <FileList 
                            files={files} 
                            removeFile={removeFile} 
                            /> */
                            // </div>

                            )
                        }
                            {/* <input type="file" name="file" onChange={(e) => this.handleFile(e)} /> */}
                            <br/>
                            {/* <button type="button">Upload </button> */}
                        <div className="form-group-col-12">
                            <textarea 
                                name="message"
                                rows="5" 
                                cols="30"
                                className="form-control" 
                                placeholder="Πληκτρολογήστε το μήνυμα σας..." 
                                required />
                        </div>
                    </div>
                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" name="agreement" id="agreement" required />
                                <label htmlFor="agreemet" className="form-check" >
                                ΕΧΩ ΔΙΑΒΑΣΕΙ ΚΑΙ ΑΠΟΔΕΧΟΜΑΙ ΤΟΥΣ  
                                
                                <a href="#" className="policy">
                                    ΟΡΟΥΣ ΧΡΗΣΗΣ
                                </a>
                                 ΚΑΙ ΤΗΝ
                                <a href="#" className="policy">
                                    ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ
                                </a>
                                </label>
                            </div>
                        </div>
                        <div className="text-center">
                            {/* <button 
                            type="submit" 
                            onSubmit={sendEmail} 
                            value="Send" 
                            className="btn btn-primary">
                                ΑΠΟΣΤΟΛΗ
                            </button> */}
                        </div>
                </form>
            
        </div>
    </div>
  )
}

