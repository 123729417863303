import React from 'react'
import ServiceCol from './ServiceCol'
import { services } from '../../../data/data'

function Services(props) {
  return (
    <section id="ourservices">
        <div className="services-container-row">
            <div className="services-row">
            
                <ServiceCol 
                data= { services }
                
                />
            </div>
        </div>
    </section>
  )
}

export default Services