import React from 'react'

function TechItems(props) {
  return (
    <div className="tech-container" >
      <div className="tech-img">
        <img 
          src={props.data.image}
          alt="1233" 
          className="tech-image" />
      </div>
        <div className="tech-box">
          <div className="technology-title">
            <h2 className="technology">
            {props.data.title}
            </h2>
          </div>
          <h1 className="tech-title">
            {props.data.header }
          </h1>
          <hr className="tech-hr" />
          <p className="tech-para">
            { props.data.para }
          <br/>
            {props.data.para_1}
          <br/>
            {props.data.para_2}
          <br/>

            </p>
        </div>
    </div>
  )
}

export default TechItems