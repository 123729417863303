import React from 'react'

function CompanyCol1(props) {
  return (
    <div className="company-col company_1">
    <div className="title-container">
    <h2 className="addon-title">
        {props.companycol.title}
    </h2>            
    </div>
    <h1 className="addon-text">
        {props.companycol.text}
    </h1>
    <hr />
    <p className="addon-desc">{props.companycol.desc}</p>
    
</div>
  )
}

export default CompanyCol1