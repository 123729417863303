import React from "react";
// import {  useHistory, } from 'react-router-dom';
import HeaderContact from "./HeaderContact";
import Logo from "./Logo";
import SideBar from "./SideBar";
import HeaderMenu from "./HeaderMenu";
import './Header.css'
import { images, linkNames } from '../../data/data'


function Header() {
  
  // fixed links
  // document.getElementById("bodyId").addEventListener("click", function(){
    // document.getElementById("buttons").classList.add("d-none");
    // });


//   let history = useHistory();

//   function handleClick() {
//     history.push("/home");
//   }
  

  return (

    <div className="header-overlap " >

      <HeaderContact />

    <div className="header-overlap header-2">

    <header id="sp-header" className="header-2">
      <div className="container">
        <div className="container-inner">
          <div className="row justify-container-between" >
          
          {/* LOGO */}

          <Logo
          images = {images} />

          {/* MENU */}

          <div id="sp-menu" className="col-auto flex-auto">
            <div 
            className=
            "sp-column d-flex justify-content-end align-items-center d-grid">

           {/* SIDE BAR */}

            <SideBar
            images = { images }
            linkNames={ linkNames }
            id="bodyId"
            // onClick={() => closeMenu()}
            />

             <HeaderMenu />
            </div>
          </div>
          </div>
        </div>
      </div>
    </header>
    </div>
    </div>
  )
}

export default Header