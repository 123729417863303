import React from 'react'
// import { MdDragHandle } from 'react-icons/md'
// import { catalog } from '../../../data/data'



function ProjectListLinks(props) {

  return (

    <div className="projects-list-title">
    {props.projectlinks.map((data) =>
    <a className='list_items'
    href='/'
    key={data.key}
    onClick={props.handle}
    // href={data.value}


    value={data.value}

    // onClick={() => filterResult({'category': data.category})}
   
    >
      {data.title}
    </a>
    
        )}
</div>
  )
    }

export default ProjectListLinks