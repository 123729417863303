import React from 'react'

function ProjectItemHeader(props) {
  return (
    <div key= {props.data.key}className="sppb-section-title">
    <div className="service-title-container">
      <h2 className="services-title">
        {props.data.subtitle}
      </h2>
    </div>
    <h2 className="sppb-title-heading">
      {props.data.header}
      <br/>
      <hr className="hr-projects" />
    </h2>
    <h3 className="title-subheading">{props.data.subheading}</h3>
  </div>
  )
}

export default ProjectItemHeader



{/* <h3 className="sppb-subtitle"> */}
// /<div className="subtitle-title"></div>