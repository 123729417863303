import React from 'react'
import './Header.css'
import HeaderContactItems from './HeaderContactItems'
import HeaderLinks from './HeaderLinks'
import { contact , banner , icons} from '../../data/data'


function HeaderContact() {

  const prasinoBanner = banner  


  return (
    <div id="sp-top-bar" className="header-2 d-md-block">
        <div className="container">
          <div className="banner-container">
          <a href={prasinoBanner.pdf} target="_blank" rel="noopener noreferrer">
          <img
            key={prasinoBanner.key}
            src={prasinoBanner.image} 
            className="Header-banner"
            alt="banner" />
          </a>
          <img src={prasinoBanner.espa} className="espaImg" alt="espa" />
          </div>
          <div className="container-inner">
            <div className="row">
              <div id="sp-top1" className="col-md-8">
                <div className="sp-column text-center text-md-start">
                  <HeaderContactItems 
                  data={contact}
                  />
                  
                </div>
              </div>
              < HeaderLinks 
              data={contact}
              icons={icons}
              />
            </div>
          </div>
        </div>
      </div>
  )
}

export default HeaderContact