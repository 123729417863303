import React from 'react'
import { companyHistory, companyPgImg } from '../../../data/data';


export default function CompanyPgItems() {
  const e = companyPgImg
  const data = companyHistory

  // const history = companyHistory
  // const [showPara, setShowPara] = useState(false)
  // const [active, setActive] = useState(types[0]);

// function clicked() {
//   setActive(active)
//   setShowPara(true)
// }


  return (
    <div className="company_pg-main">
      <div className="company_pg-left">
        <img 
        key={e.key}
        src={e.image}
         alt="" className="company_pg-image" />
      </div>
            {/* {company.map((data) => ( */}
      <div className="company_pg-right">
        <div className="company_pg-list">
          <ul id="items" className="company_pg-items">

            
            <li
            key={data.key} 
            id="item"
            className="company_pg-item"
            // active={active === type} 
            // onClick={clicked}
            >
            {data.title}
             </li>

            
            {/* <li
            // onClick={handleClick} 
            id="item"
             className="company_pg-item Mission"
             >
              ΣΚΟΠΟΣ
            </li> 
            <li id="item" 
            className="company_pg-item Vision"
            // onClick={handleClick} 
            >
              ΟΡΑΜΑ
            </li>
            <li id="item" 
            // onClick={handleClick} 
            className="company_pg-item Future"
            >
              ΜΕΛΛΟΝ
            </li> */}
          </ul>
        </div>
        

        <p id="desc" className="company_pg-desc-items">
         {data.para1}
        </p> 
        <br/>
        <p className="company_pg-desc-items">
         {data.para_title}
          <br/>
        {data.para2}
        <br/>
        {data.para3}
        <br/>
       {data.para4}
        <br/>
       {data.para5}
        </p>

        
      </div>
            {/* ))} */}
    </div>
  )
}
