import React from 'react'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
// import { Link } from 'react-router-dom'
import { team } from '../../../data/data'

function CompanyPgTeam() {
  
const data = team

  return (
    <div className="company_member-container">
    <div className="company_member-row">
      <div className="company_member-bg">
      <h2 className="company_member-title">
        ΣΧΕΤΙΚΑ ΜΕ 
      </h2>
      </div>
      <h1 className="company_member-header">
        ΤΟ ΠΡΟΣΩΠΙΚΟ ΜΑΣ 
      </h1>
      <hr className="member-hr" />
      <p className="company_member-desc">
      Λίγα λόγια για το έμπειρο προσωπικό μας.
      </p>
    </div>
    <div className="company_member">
      <div className="company_member-list">
        <ul className="company_member-items">
          {data.map((e) =>
        
          <div key={e.key} className="company_member-item-container">
          <li className="company_member-item">
            <img 
            src={e.image}
            // src={company1}
             alt="" className="copany_member-image" />
          </li>
          <ul className="company_member-social">
            <li className="company_member-social-item">
              <a className="link_team"  rel="noreferrer" href={e.facebook} target="_blank">
              <FaFacebookF/>
              </a>
            </li>
            <li className="company_member-social-item">
              <a  className="link_team" href={e.instagram} rel="noreferrer" target="_blank">
              <FaInstagram />
              </a>
            </li>
            {/* <li className="company_member-social-item">
              <FaLinkedinIn/>
            </li> */}
          </ul>
          <h2 className="company_member-h2-item">
            {e.name}
          </h2>
          <hr/>
          <p className="company_member-desc-item">
            {e.title}
          </p>
          </div>
          )}
          {/* <div className="company_member-item-container">
          <li className="company_member-item">
            <img
            src={worker1.image}
            //  src={company1} 
            alt="" className="copany_member-image" />
          </li>
          <ul className="company_member-social">
            <li className="company_member-social-item">
              <FaFacebookF/>
            </li>
            <li className="company_member-social-item">
              <FaInstagram/>
            </li>
            <li className="company_member-social-item">
              <FaLinkedinIn/>
            </li>
          </ul>
          <p className="company_member-desc-item">
            Ασλανίδης Λάζαρος Υπάλληλος 
          </p>
          </div> */}
          {/* <div className="company_member-item-container">
          <li className="company_member-item">
            <img
            //  src={company1}
              alt="" className="copany_member-image" />
          </li>
          <ul className="company_member-social">
            <li className="company_member-social-item">
              <FaFacebookF/>
            </li>
            <li className="company_member-social-item">
              <FaInstagram/>
            </li>
            <li className="company_member-social-item">
              <FaLinkedinIn/>
            </li>
            
          </ul>
          <p className="company_member-desc-item">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam iste expedita at
          </p>
          </div> */}
        </ul>
      </div>
    </div>
  </div>
  )
}

export default CompanyPgTeam