import React from 'react'
import CompanyCol1 from './CompanyCol1'
import CompanyCol2 from './CompanyCol2'
import { companycol1, companycol2 } from '../../../data/data'

function Company() {
  return (
    <section id="company-id">
        <div className="company-container-row">
            <div className="company-row">
               <CompanyCol1
                companycol = { companycol1 }
                />
                <CompanyCol2 
                companycol2 = { companycol2 }
                />
            </div>
        </div>
    </section>
  )
}

export default Company