import React from 'react'
import { Link } from 'react-router-dom'

function HeaderMenu() {
  return (
    <nav className="sp-megamenu-wrapper d-flex" role="navigation">
                
    <ul className="sp-megamenu-parent menu-animation-none  d-lg-block">
      <li className="sp-menu-item current-item active">
        <Link aria-current="page" to="/">ΑΡΧΙΚΗ</Link>
      </li>
      <li className="sp-menu-item current-item active">
        <Link aria-current="page"  to="/company" >Η ΕΤΑΙΡΙΑ</Link>
      </li>
      <li className="sp-menu-item current-item active">
        <Link aria-current="page" to="/services">ΥΠΗΡΕΣΙΕΣ</Link>
      </li>
      <li className="sp-menu-item current-item active">
        <Link aria-current="page" to="/projects">PROJECTS</Link>
      </li>
      <li className="sp-menu-item current-item active">
        <Link aria-current="page" to="/blog">BLOG</Link>
      </li>
      <li className="sp-menu-item current-item active">
        <Link aria-current="page" to="/contact">ΕΠΙΚΟΙΝΩΝΊΑ</Link>
      </li>
      
    </ul>
    
  </nav>
  )
}

export default HeaderMenu