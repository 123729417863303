import React from 'react'
// import { testimonialDataTest } from '../../../data/data'
// import { testimonialDataTest } from '../../../data/data'
import { testimonialData, testimonialData2 } from './TestimonialData'
import TestimonialItems from './TestimonialItems'

function Testimonials(props) {

  const data = testimonialData
  return (
         <div className="testimonials">
        <div className="our-clients">
          <div className="testimonials-shape">
          <h2 className="testimonials-category">
            {data.clients}
            {/* Οι Πελάτες μας */}
          </h2>
          </div>
          <p className="testimonials-para">
            {/* {props.data.ratings} */}
            Ρωτήσαμε τους πέλατες μας για την γνώμη τους
          </p>
          <hr/>
        </div>
       <TestimonialItems
       data = { testimonialData2 }
        />
      </div>
  )
}

export default Testimonials