import React from 'react'

function ServicesColMain(props) {
  return (      
    <div>
        <div className="service-title-container">
                    <h2 className={props.servicemain.h2}>
                        {props.servicemain.header}
                    </h2>
                    </div>
                    <h1 className={props.servicemain.h1}>
                        {props.servicemain.main}
                    </h1>
                    <hr className={props.servicemain.hr}/>
                    <p className={props.servicemain.servicesdesc}>
                    {props.servicemain.desc}
                    </p>
    </div>
  )
}

export default ServicesColMain