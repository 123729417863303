import React from 'react'
import{  FaFacebookSquare, FaInstagram, FaTiktok } from 'react-icons/fa';

function HeaderLinks(props) {
  return (
    <div id="sp-top2" className="col-md-4">
    <div className="sp-column text-center text-md-end">
      <ul className="social-icons">
        <li className="social-icon-facebook">
          <a rel="noopener noreferrer" href={props.data.facebook} target="_blank" aria-label="Facebook">
            <FaFacebookSquare />
          </a>
        </li>
        <li className="social-icon-instagram">
          <a rel="noopener noreferrer" href={props.data.instagram} target="_blank" aria-label="Instagram">
            <FaInstagram />
          </a>
        </li>
        <li className="social-icon-tiktok">
          <a rel="noopener noreferrer" href={props.data.tiktok} target="_blank" aria-label="tiktok">
            <FaTiktok />
          </a>
        </li>
        <li className="social-icon-accesibility">
          <img className='social-icon-accesibility-img' src={props.icons.img} alt="disability icon" />
        </li>
        
      </ul>
    </div>
    </div>
  )
}

export default HeaderLinks