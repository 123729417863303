import React from 'react'

function BlogHeader() {
  return (
    <div className="blog-row">
    <div className="our-blog-container">
    <h2 className="blog-header">
      Master Blog
    </h2>
    </div>
    <h1 className="blog-title">
      Το Blog μας 
    </h1>
    <hr className="blog-hr"/>
    {/* <p className="blog-para">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit minus mollitia id. Consectetur.
    </p> */}
</div>
  )
}

export default BlogHeader