import React from "react";
// import { FaMapMarkerAlt, FaEnvelope, FaPhoneSquareAlt, FaFax } from 'react-icons/fa'
// import { Link } from 'react-router-dom'
import { PageBgHeader } from "../../../data/data";
import ContactForm from "../Contact/ContactForm";
import { ContactSide } from "../Contact/ContactSide";
import { Map } from "../Contact/Map";
import LinkedPg from "../PagesBg/LinkedPg";
import PagesBg from "../PagesBg/PagesBg";
// import { 
//     useJsApiLoader,
//     GoogleMap,
//     Marker,
//     InfoWindow,
//     Data
//  } from '@react-google-maps/api'
// import { Map } from "../Contact/Map";
// import { ContactSide } from "../Contact/ContactSide";
// import ContactForm from "../Contact/ContactForm";
// import Contact from '../Components/contact/Contact'
//  import data from '../data/data';




const ContactPage = () => {

//     function yesnoCheck(that) {
//         if (that.value == "other") {
//       alert("check");
//             document.getElementById("ifYes").style.display = "block";
//         } else {
//             document.getElementById("ifYes").style.display = "none";
//         }
//     }


// const [name, setName] = useState('');
// const [email, setEmail] = useState('');
// const [message, setMessage] = useState('');
// const form = useRef();

    
    
// const {isLoaded} = useJsApiLoader({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     })
    
//     if (!isLoaded) {
//         return( 
//         <>
//         <div className="loader"></div>
//         <h1 className="loader-h1">Loading...</h1>
//         </>
//         )
//     }
    
    

        return (
        <div className="services_page">
              <PagesBg 
              e = { PageBgHeader } />
            <div className="company_page-container p-top10">
                <h1 className="company_pg-title">
                     ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ
                </h1>
                <div className="company_page-header">
                   <LinkedPg title = "ΕΠΙΚΟΙΝΩΝΙΑ" />
                </div>
            </div>
            <div className="contact-map-main">
                <div className="contact-map-container">
                    <Map 
                    />
                </div>
                <div className="contact_rcol">
                   <ContactSide
                    />
                </div>
            </div>
          <ContactForm />
            
            </div>
      )
    
}

export default ContactPage




