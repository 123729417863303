import { v4 as uuid }  from 'uuid'
import {  FaTools, FaMapMarker, FaFax, FaPhoneSquareAlt, FaEnvelope } from 'react-icons/fa';

// import {  FaMapMarker, FaFax, FaPhoneSquareAlt, FaEnvelope } from 'react-icons/fa';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { BiTimer } from 'react-icons/bi';



 export const home = {
    
    leader : "Ηγετης στις on-site υπηρεσιες",
    welcome : "Η MASTER OE σας καλωσορίζει στην ιστοσελίδα της",
    viewmore : "Περισσότερα",
    videobg : "/video/video.MOV"
}
 



export const contact = 
{ phone:"+302463026221",
  tel: "+306976510926",
  email: "masteroe2014@gmail.com",
  facebook: "https://www.facebook.com/masterstergiou",
  instagram: "https://www.instagram.com/master_stergiou/",
  tiktok: "https://www.tiktok.com/@master_stergiou"
}

export const images = 
{
    
    logo: "/images/master_logo2.png"
}



export const linkNames = 
[
    {
        key: uuid(),
        name: "ΑΡΧΙΚΗ",
        to: "/",
        value:""
    },
    {
        key: uuid(),
        name: "Η ΕΤΑΙΡΙΑ",
        to: "/company",
        value:"company"
    },
    {
        key: uuid(),
        name: "ΥΠΗΡΕΣΙΕΣ",
        to: "/services",
        value:"services"
    },
    {
        key: uuid(),
        name: "PROJECTS",
        to: "/projects",
        value:"projects"
    },
    {
        key: uuid(),
        name: "BLOG",
        to: "/blog",
        value:"blog"
    },
    {
        key: uuid(),
        name: "ΕΠΙΚΟΙΝΩΝΙΑ",
        to: "/contact",
        value:"contact"
    }
]


export const companycol1 = 
{
    key: uuid(),
    title:"ΠΟΙΟΙ ΕΙΜΑΣΤΕ",
    text:"Οn-site εργασίες",
    desc:"Πάνω από δύο δεκαετίες δραστηριοποιούμαστε στις on-site υπηρεσίες, οπως στη συντήρηση και στην πλύση μηχανημάτων έργου, με κύριους συνεργάτες την ΔΕΗ ΑΕ καθώς και αλλες τεχνικές εταιρείες που δραστηριοποιούνται στη περιφέρεια Δυτικής Μακεδονίας."

}

export const companycol2 = 
{
    key: uuid(),
    seed:"/images/iconServices/eco_mode.png",
    excavator:"/images/iconServices/Technology_modern.png",
    eco:"Αγάπη για το περιβάλλον",
    certificate:"Πιστοποιήσεις ΙSO 9001:2015 και ΙSO 14001:2015",
    modernTech:"Σύγχρονος εξοπλισμός",
    modernTechDesc:"Η ΜΑΣΤΕΡ ΟΕ διαθέτει σύγχρονο εξοπλισμό για την υλοποίηση αποφράξεων, την καθαριότητα αγωγών και φρεατίων, τον εκχιονισμό δρόμων καθώς και το πλύσιμο μηχανημάτων έργου."
}


export const servicemain = 
{
    key:uuid(),
    h2:"services-title",
    header: "ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ",
    h1:"main-services",
    main:"ΚΥΡΙΕΣ ΥΠΗΡΕΣΙΕΣ",
    hr:"right-hr",
    servicesdesc:"services-desc",
    desc:"Η εταιρεία Ma.Ster. OE βρίσκεται στο 5ο χλμ Πτολεμαΐδας- Κοζάνης.",
    services_img : "/images/Hero.jpeg"
}



export const services = 
[ 
    {
        key: uuid(),
        classnameservice:"service-1",
        classnameimg:"service-img",
        image: "/images/iconServices/Car_Wash.png",
        title: "ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ ΕΡΓΟΥ",
        para:" H εταιρεία μας, διαθέτει σύγχρονο φορτηγό με τις κατάλληλες από το νόμο άδειες (ΜΕ-Πλυστικό) κατάλληλο για πρόσβαση σε ορυχεία και κάθε είδους τερέν.  "
    },

    {
        key:uuid(),
        image:"/images/iconServices/Blockages.png",
        title:"ΑΠΟΦΡΑΞΕΙΣ",
        para: " ΜΑΣΤΕΡ ΟΕ είναι εξοπλισμένη με σύγχρονο εξοπλισμό για την υλοποίηση αποφράξεων και την καθαριότητα αγωγών και φρεατίων, ενώ δίνεται και η δυνατότητα βιντεοσκόπησης αγωγών με σκοπό την εύρεση ζημιών - διαρροών.",
    },

    {
        key:uuid(),
        image:"/images/iconServices/Road_Clean.png",
        title:"ΚΑΘΑΡΙΟΤΗΤΑ ΔΡΟΜΩΝ",
        para:"Η ΜΑΣΤΕΡ ΟΕ διαθέτει εξοπλισμό κατάλληλο για την καθαριότητα χόρτων - θάμνων  και κλάδεμα δέντρων κατα μήκος των δρόμων και των σιδηροδρομικών γραμμών. ",
    },

    {
        key:uuid(),
        image:"/images/iconServices/Snow_Clean.png",
        title:"ΕΚΧΙΟΝΙΣΜΟΣ",
        para:"Η ΜΑΣΤΕΡ ΟΕ διαθέτει εξοπλισμό κατάλληλο για την καθαριότητα χόρτων- θάμνων  και κλάδεμα δέντρων κατα μήκος των δρόμων και των σιδηροδρομικών γραμμών."
    }
]

export const projectitemheader = 
    {
        key: uuid(),
        subtitle:"Η ΔΟΥΛΕΙΑ ΜΑΣ",
        header:"ΠΡΟΣΦΑΤΕΣ ΔΟΥΛΕΙΕΣ",
        subheading:"Πάνω από δύο δεκαετίες δραστηριοποιούμαστε στις on-site υπηρεσίες, οπως στη συντήρηση και στην πλύση μηχανημάτων έργου, με κύριους συνεργάτες την ΔΕΗ ΑΕ καθώς και αλλες τεχνικές εταιρείες που δραστηριοποιούνται στη περιφέρεια Δυτικής Μακεδονίας.  "
    }

export const nextproject = 
{
    key:uuid(),
    h3:"ΕΤΟΙΜΟΙ ΓΙΑ ΤΟ ΕΠΟΜΕΝΟ ΕΡΓΟ ΜΑΣ ?",
    contactnow:"ΕΠΙΚΟΙΝΩΝΗΣΕ ΤΩΡΑ"
}

export const projectlinks = 
[
    {
        key:uuid(),
        title:'ΠΡΟΒΟΛΗ ΟΛΩΝ',
        value:""

    },
    {
        key:uuid(),
        title:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΙΜΑΤΩΝ",
        value:"carwash"
    },
    {
        key:uuid(),
        title:"ΑΠΟΦΡΑΞΕΙΣ",
        value:"blockages"
    },
    {
        key:uuid(),
        title:"ΑΠΟΧΙΟΝΙΣΜΟΣ",
        value:"snow"
    },
    {
        key:uuid(),
        title:"ΚΑΘΑΡΙΟΤΗΤΑ ΔΡΟΜΩΝ",
        value:"road"
    }
]


export const catalog = 
[
    {
        key:uuid(),
        img:"/images/photoProjects/blockages_1.jpg",
        h3:"ΑΠΟΦΡΑΞΕΙΣ",
        content:"Αποφραξεις",
        value:"blockages"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/blockages_2.jpg",
        h3:"ΑΠΟΦΡΑΞΕΙΣ",
        content:"Αποφραξεις",
        value:"blockages"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/blockages_3.jpg",
        h3:"ΑΠΟΦΡΑΞΕΙΣ",
        content:"Αποφραξεις",
        value:"blockages"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/blockages_4.jpg",
        h3:"ΑΠΟΦΡΑΞΕΙΣ",
        content:"Αποφραξεις",
        value:"blockages"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/blockages_5.jpg",
        h3:"ΑΠΟΦΡΑΞΕΙΣ",
        content:"Αποφραξεις",
        value:"blockages"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/car-wash-1.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλυσιμο μηχανηματων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/car-wash-2.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλυσιμο μηχανηματων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/car-wash-3.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλυσιμο μηχανηματων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/car-wash-4.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλυσιμο μηχανηματων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/car-wash-5.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλυσιμο μηχανηματων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/road_1.png",
        h3:"ΚΑΘΑΡΙΟΤΗΤΑ ΔΡΟΜΩΝ",
        content:"Καθαριότητα Δρόμων",
        value:"road"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/road_2.png",
        h3:"ΚΑΘΑΡΙΟΤΗΤΑ ΔΡΟΜΩΝ",
        content:"Καθαριότητα Δρόμων",
        value:"road"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/snow_1.png",
        h3:"ΑΠΟΧΙΟΝΙΣΜΟΣ",
        content:"Αποχιονισμός",
        value:"snow"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/snow_2.png",
        h3:"ΑΠΟΧΙΟΝΙΣΜΟΣ",
        content:"Αποχιονισμός",
        value:"snow"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/tree_1.jpeg",
        h3:"ΚΑΘΑΡΙΟΤΗΤΑ ΔΡΟΜΩΝ",
        content:"Καθαριότητα Δρόμων",
        value:"road"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/wash_1.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλύσιμο μηχανημάτων",
        value:"carwash"
    },
    {
        key:uuid(),
        img:"/images/photoProjects/wash_2.jpeg",
        h3:"ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
        content:"Πλύσιμο μηχανημάτων",
        value:"carwash"
    }
]


export const whyusitem =


[  
    {
        key:uuid(),
        title: "Η ΕΠΙΛΟΓΗ ΣΑΣ",
        header:"Η ΕΤΑΙΡΕΙΑ ΜΑΣ",
        para: "Απο το 2014 η εταιρεία μας ειναι πρώτη στην προτίμηση σας. "
    }
]

export const whychild =
[

    {
        key:uuid(),
        class:"why-us-item our-experience",
        title:"01",
        header:"Χρόνια εμπειρίας",
        para:"Πάνω από δύο δεκαετίες δραστηριοποιούμαστε στις on-site υπηρεσίες, οπως στη συντήρηση και στην πλύση μηχανημάτων έργου, με κύριους συνεργάτες την ΔΕΗ ΑΕ καθώς και αλλες τεχνικές εταιρείες που δραστηριοποιούνται στη περιφέρεια Δυτικής Μακεδονίας."
    },
    {
        key:uuid(),
        class:"why-us-item our-safety",
        title:"02",
        header:"Ποιότητα και εγγύηση",
        para:"Πιστοποιήσεις ΙSO 9001:2015 και ΙSO 14001:2015"
    },
    {
        key:uuid(),
        class:"why-us-item our-tech",
        title:"03",
        header:"Τεχνολογία και καινοτομία",
        para:"Η ΜΑΣΤΕΡ ΟΕ διαθέτει σύγχρονο εξοπλισμό για την υλοποίηση κάθε τύπου εργασίας."
    }
]
     


export const tech = 

    {
        key:uuid(),
        image:"/images/photoProjects/machine_10.jpeg",
        title:"ΤΕΧΝΟΛΟΓΙΑ",
        header:"Σύγχρονα μηχανήματα",
        para:"• Σύγχρονος εξοπλισμός εκχιονισμού",
        para_1:"• Σύγχρονος εξοπλισμός κατάλληλος για την καθαριότητα χόρτων - θάμνων  και κλάδεμα δέντρων κατα μήκος των δρόμων και των σιδηροδρομικών γραμμών ",
        para_2:"• Εξοπλισμός για την υλοποίηση αποφράξεων και την καθαριότητα αγωγών και φρεατίων"
    }




export const PageBgHeader = 
{
    key:uuid(),
    img: "images/deh.jpg",
    className:"bg-company-pg"
}


export const CompanyPg = 
{
    title:"Η ΕΤΑΙΡΕΙΑ ΜΑΣ"

}

export  const ServicePg = 
{
    title:"ΥΠΗΡΕΣΙΕΣ"
}

export const icons =
{
    img: '/images/disablility.png'
}

export const team =
[
    {
        key:uuid(),
        name:"Στεργίου Τριαντάφυλλος ",
        title:"CEO / ΙΔΡΥΤΗΣ ",
        image:"/images/team/ceo.jpeg",
        insta:"https://www.instagram.com/30fullos_ster/" ,
        facebook:"https://www.facebook.com/triantafyllos.stergiou"
    }
    // {
    //     key:uuid(),
    //     name:"Ασλανίδης Λάζαρος",
    //     title:" ΥΠΕΥΘΥΝΟΣ ΓΙΑ ΤΟ ΠΛΥΣΙΜΟ ΜΗΧΑΝΗΜΑΤΩΝ",
    //     image:"/images/team/team1.jpeg",
    //     instagram:"https://www.instagram.com/lazaros_aslanidis_/",
    //     facebook:"https://www.facebook.com/profile.php?id=100070677116295"
    // }
]




//  USED FOR WEFIFFERENT 

export const whyitem =    
        {
            key: uuid(),
            li:"why-us-item our-company",
            div_hover:"why-us-hover",
            h2:"why-choose",
            title:"ΓΙΑΤΙ ΕΜΑΣ ;",
            h3:"why-company-title",
            header:"ΔΙΟΤΙ ΔΙΑΦΕΡΟΥΜΕ ",
            p:"why-company why-left",
            para: "Η ΕΤΑΙΡΕΙΑΣ ΜΑΣ ΕΧΕΙ ΩΣ ΣΤΟΧΟ ΤΗΝ ΙΚΑΝΟΠΟΙΗΣΗ ΤΟΥ ΠΕΛΑΤΗ ΚΑΙ ΤΗΝ ΓΡΗΓΟΓΗ ΕΞΥΠΗΡΕΤΗΣΗ ΤΟΥ"
        }


         
export const whyitems = 
[
        {
            key:uuid(),
            icon: <FaTools/>,
            li:"why-us-item our-exprerience",
            title:"ΠΟΙΟΤΗΤΑ",
            para:"Η ΜΑΣΤΕΡ ΟΕ έχει σαν σκοπο της την ποιότητα και την αξιοπιστία  ΕΧΕΙ ΣΑΝ ΣΚΟΠΟ ΤΗΣ ΤΗΝ ΠΟΙΟΤΗΤΑ ΚΑΙ ΤΗΝ ΑΞΙΟΠΙΣΙΤΑ."
        },
        {
            key:uuid(),
            icon: <BiTimer/>,
            li:"why-us-item our-safety",
            title:"ΣΥΝΕΠΕΙΑ",
            para:"Η ΣΥΝΕΠΕΙΑ ΕΔΩ ΚΑΙ ΣΧΕΔΟΝ ΔΥΟ ΔΕΚΑΕΤΊΕΣ ΕΧΕΙ ΚΑΝΕΙ ΤΗΝ ΕΤΑΙΡΕΙΑ ΜΑΣ ΓΝΩΣΤΗ ΣΤΗΝ ΔΥΤΙΚΗ ΚΑΙ ΚΕΝΤΡΙΚΗ ΜΑΚΕΔΟΝΊΑ"
        },
        
        {
            key:uuid(),
            icon: <RiSecurePaymentFill/>,
            li:"why-us-item our-tech",
            title:"TΕΧΝΟΛΟΓΙΑ ΚΑΙ ΚΑΙΝΟΤΟΜΙΑ",
            para:"ΣΕ ΕΝΑΝ ΚΟΣΜΟ ΜΕ ΓΡΗΓΟΡΟ ΡΥΘΜΟ ΑΝΑΠΤΥΞΗΣ ΤΗΣ ΤΕΧΝΟΛΟΓΙΑΣ, Η ΜΑΣΤΕΡ ΟΕ ΕΧΕΙ ΚΑΤΑΦΕΡΕΙ ΝΑ ΦΑΝΕΙ ΑΝΤΑΞΙΑ."
        },

]



// COMAPANY DATA 

export const companyPgImg = 

    {
        key: uuid(),
        image:'/images/deh.jpg',
    
    }


export const companyHistory = {
        key: uuid(),
        title:'ΙΣΤΟΡΙΑ',
        para1:' Η εταιρεία Ma.Ster OE βρίσκεται στο 5ο χλμ Πτολεμαΐδας - Κοζάνης σε ιδιόκτητες εγκαταστάσεις και δραστηριοποιείται στη περιφέρεια Δυτικής Μακεδονίας.Πάνω από δύο δεκαετίες δραστηριοποιούμαστε στις on-site υπηρεσίες, οπως στη συντήρηση και στην πλύση μηχανημάτων έργου, με κύριους συνεργάτες την ΔΕΗ ΑΕ καθώς και αλλες τεχνικές εταιρείες που δραστηριοποιούνται στη περιφέρεια Δυτικής Μακεδονίας.',
        para_title:"Οι υπηρεσίες που παρέχουμε:",
        para2:'- πλύσιμο μηχανημάτων έργου',
        para3:"- αποφράξεις / καθαριότητα φρεατίων και βιντεοσκόπηση αγωγών",
        para4:"- εκχιονισμό δρόμων",
        para5:"- καθαριότητα δρόμων"
    }

export const banner = {
    key: uuid(),
    // image:'/images/banners/banner_prasino_tameio.jpg'
    image:'/images/banners/BannerEspa.png',
    pdf:'/images/banners/PRASINO-TAMEIO_ARXEIO.pdf',
    espa:'/images/banners/bannerespa2020.png'
}

    // FOOTER 




    export const footerItems = 
    [
        {
            key:uuid(),
            class:"footer-col-2",
            title:"Η ΕΤΑΙΡΕΙΑ ΜΑΣ",
            about:"ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ",
            services:"ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ",
            gallery:"ΟΙ ΔΟΥΛΕΙΕΣ ΜΑΣ",
            news:"ΝΕΑ ΚΑΙ ΟΔΗΓΟΙ ",
            contact:"ΕΠΙΚΟΙΝΩΝΙΑ"
        },
        {
            key:uuid(),
            class:"footer-col-3",
            title:"ΓΡΗΓΟΡΟΙ ΣΥΝΔΕΣΜΟΙ",
            about:"ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ",
            services:"ΟΡΟΙ ΚΑΙ ΠΡΟΥΠΟΘΕΣΕΙΣ",
            gallery:"ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ",
            news:"ΑΞΙΟΛΟΓΗΣΕΙΣ ΠΕΛΑΤΩΝ ",
            contact:"Η ΟΜΑΔΑ ΜΑΣ"
        }   
    ]



export const footerLinks =
[
    {
        key:uuid(),
        class:"footer-bt-1",
        classNm:"bt-1-left",
        href:"https://www.google.gr/maps/place/Master+oe/@40.4927119,21.7111197,17z/data=!3m1!4b1!4m5!3m4!1s0x6216f43578856d5d:0x230cdf045cbc6319!8m2!3d40.4927119!4d21.7133084",
        icon:<FaMapMarker />,
        classIcon:"MapMarker",
        bt_right:"bt-1-right",
        title:"ΔΙΕΥΘΗΝΣΗ",
        desc:"ΕΛΛΑΔΑ",
        desc2:"ΠΤΟΛΕΜΑΪ́ΔΑ"
    },
    {
        key:uuid(),
        class:"footer-bt-2",
        classNm:"bt-2-left",
        href:"tel:+302463026221",
        icon:<FaFax />,
        classIcon:"",
        bt_right:"bt-2-right",
        title:"FAX",
        desc:"+302463026221",
        desc2:""
    },
    {
        key:uuid(),
        class:"footer-bt-3",
        classNm:"bt-3-left",
        href:"tel:+306976510926",
        icon:<FaPhoneSquareAlt />,
        classIcon:"",
        bt_right:"bt-3-right",
        title:"ΤΗΛΕΦΩΝΟ",
        desc:"+306976510926",
        // desc1:" +302463026221",
        desc2:""
    },
    {
        key:uuid(),
        class:"footer-bt-4",
        classNm:"bt-4-left",
        href:"mailto:masteroe2014@gmail.com",
        icon:<FaEnvelope />,
        classIcon:"",
        bt_right:"bt-4-right",
        title:"EMAIL",
        desc:"masteroe2014@gmail.com",
        desc2:""
    }
]