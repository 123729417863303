import React from 'react'
import { catalog, PageBgHeader } from '../../../data/data'
import GalleryImages from '../Gallery/GalleryImages'
import LinkedPg from '../PagesBg/LinkedPg'
import PagesBg from '../PagesBg/PagesBg'


function ProjectsPage() {
  return (
    <div className="service_page">
       <PagesBg 
       e = {PageBgHeader}
       />
        <div className="company_page-container p-top10">
            <h1 className="company_pg-title">
                ΟΙ ΔΟΥΛΕΙΕΣ ΜΑΣ 
            </h1>
            <div className="company_page-header">
                <LinkedPg  
                title = "ΕΡΓΑΣΙΕΣ"
                />
            </div>
        </div>
        <div className="projects-pg-main">
            <div className="projects-pg-container">
                <div className="projects-pg-row">
                    <GalleryImages 
                    GalleryImages={catalog} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectsPage