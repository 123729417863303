import React from 'react';
// import Benefits from '../Benefits/Benefits';
import ContactSec from '../Contact/ContactSec';
import WeDifferent from '../Different/Different';
import { ServicePg, services  } from '../../../data/data';
import PagesBg from '../PagesBg/PagesBg';
import { PageBgHeader } from '../../../data/data';
import LinkedPg from '../PagesBg/LinkedPg';
import PgAbout from '../Company/PgAbout';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import BlogPhoto from '../assets/photoProjects/car-wash-2.jpeg';
// import Benefits from '../Components/Benefits';
// import ContactSec from '../Components/ContactSec';
// import WeDifferent from '../Components/WeDifferent';

function ServicesPage() {
    const data = ServicePg
    const e = services
  return (
    <div className="services_page">
        <PagesBg 
        e = { PageBgHeader}
        />
        <div className="company_page-container p-top10">
            <h1 className="company_pg-title">
                {data.title}
            </h1>
        <div className="company_page-header">
            <LinkedPg 
            title = "ΥΠΗΡΕΣΙΕΣ "
            />
        </div>
            <div className="company_pg-row services_pg-row">
                <PgAbout 
                about = "ΟΙ ΚΥΡΙΕΣ"
                header = "ΥΠΗΡΕΣΙΕΣ"
                para = "Βρισκίμαστε εδω για να σας προσφέρουμε υπηρεσίες σε τιμές προσιτές για όλους. "
                />
            <div className="blog-items s-width">
                <div className="blog-items-container">
                    <ul className="blog-item-container">
                        {e.map((data) => (
                        <div key={data.key} className="blog-article m-50">
                            <li className="blog-item service-item_pg">
                                <img 
                                src={data.image}
                                alt="" className="blog-img" />
                                <div className="blog-text-container">
                                    <h1 className="blog-item-title">
                                        {data.title}
                                    </h1>
                                    <p className="blog-para-item">
                                      {data.para}
                                    </p>
                                </div>
                            </li>
                        </div>
                        ))}
                    </ul>
                </div>
            </div>
            </div>
        </div>
        <WeDifferent />
        <ContactSec />
        {/* <Benefits /> */}
    </div>
  )
}

export default ServicesPage