import React from 'react'

function ContactSec() {
  return (
    <div className="contact_sec">
        <div className="contact_sec-container">
                <div className="company_pg-bg">
                    <h2 className="company_title-about">
                        ΤΙ ΨΑΧΝΕΙΣ ; 
                    </h2>
                </div>
                <h1 className="contact_sec-header">

                </h1>
                <hr className="contact_sec-hr"/>
                <p className="contact_sec-desc">
                    ΨΑΧΝΕΙΣ ΔΟΥΛΕΙΑ  Ή  ΘΕΛΕΙΣ ΝΑ ΣΥΝΕΡΓΑΣΤΕΙΣ ΜΑΖΙ ΜΑΣ ;
                </p>
                    <a href="/contact" className="contact-us">
                        ΕΠΙΚΟΙΝΩΝΗΣΕ ΤΩΡΑ 
                    </a>
        </div>
    </div>
  )
}

export default ContactSec