import React from 'react'
import{ FaPhoneAlt, FaMobileAlt, FaEnvelope  } from 'react-icons/fa';
import './Header.css'


function HeaderContactItems(props) {
  


  return (
    <ul className="sp-contact-info">
    <li className="sp-contact-phone">
      <FaPhoneAlt />
      <a href={"tel:" + props.data.phone} > {props.data.phone}</a>
    </li>
    <li className="sp-contact-mobile">
      <FaMobileAlt />
      <a href={"tel:"+ props.data.tel}>{props.data.tel}</a> 
    </li>
    <li className="sp-contact-email">
      <FaEnvelope />
      <a href={"mailto:" + props.data.email}> {props.data.email}</a>
    </li>
  </ul>
  )
}

export default HeaderContactItems