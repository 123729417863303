import React, {useState} from "react";
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
// import { Link } from 'react-router-dom';
import LinkItems from "./LinkItems";
// import { linkNames } from '../../data/data'
// import { Link } from "react-router-dom";




function SideBar(props) {
    // Toogle Menu
  const [navbarOpen, setNavbarOpen] = useState(false);


  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const closeMenu = () => {
    setNavbarOpen(false)
  }


  return (

    <nav className="navBar">
            <button onClick={handleToggle} className="sidebar-toggle"  aria-label={navbarOpen ? "Close navigation menu" : "Open navigation menu"}>
              {navbarOpen ? (
                <MdClose 
                  style={{ 
                  color: "#fff",
                  width: "40px", 
                  height: "40px" 
                }}
                 />
              )
               :
              (
                <FiMenu 
                style={{ 
                  color: "#7b7b7b", 
                  width: "40px", 
                  height: "40px" 
                }} 
                />
              )}
            </button>
            
            <ul className={`menuNav ${navbarOpen 
              ?
              "showMenu" 
              : 
              ""
              }
              `}>

              {/* SIDE BAR */}
              
              <ul className="sidebar-parent">

                <LinkItems 
                closeMenu={closeMenu}
                />

               
                  <img alt='boo' className="sidebar-logo" src={props.images.logo}/>
                </ul>
            </ul>

            </nav>
  
  )
}

export default SideBar