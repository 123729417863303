import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import { testimonialDataTest } from "../../../data/data";
// import { testimonialData2 } from "./TestimonialData";



function TestimonialItems(props) {
// const data = testimonialDataTest.data
  // const data = testimonialData2.data
  return (
    <div className="main-testimonials">

    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={6100}
    >
      {props.data.map((e) =>
      <div className="image_container" key={e.key}>
        <img src={e.image} alt="testimonials"/>
        <div className="myCarousel">
          <h3>{e.name} </h3>
          <h4>{e.job}</h4>
          <p>
            {e.desc}
          </p>
          {/* <div className="rating">
          {e.rating}
          </div> */}
        </div>
      </div>
       )}
    </Carousel>
      </div>
  )
}

export default TestimonialItems