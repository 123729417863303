import React from 'react'
// import CompanyPgItems from './CompanyPgItems'

function PgAbout(props) {
  return (
    <div className="company_pg-row">
    <div className="company_pg-about">
      <div className="company_pg-container ">
        <div className="company_pg-bg ">
        <h2 className="company_title-about">
          {props.about} 
        </h2>
        </div>
        <h1 className="company_title-header">
          {props.header}
        </h1>
        <hr className="company_pg-hr"/>
      </div>
      <p className="company_pg-desc">
        {props.para}
      </p>
    </div>
    
  </div>
  )
}

export default PgAbout