import React from 'react'
import { projectlinks } from '../../../data/data'
import GalleryImages from '../Gallery/GalleryImages'
// import NextProject from '../NextProject'
// import ProjectCatalog from './ProjectCatalog'
import ProjectListLinks from './ProjectListLinks'


function ProjectItemMain(props) {


  return (
    <div className="projects-column">
    <div className="projects-column-wrap">
    
         <ProjectListLinks 
         projectlinks = { projectlinks }/>
      
      <div className="projects-list">
        <div>
          <GalleryImages
          />
          {/* <ProjectCatalog 
          catalog = { catalog } /> */}
        </div> 
        
           {/* <NextProject 
           nextdata = { nextproject }
           /> */}
      </div>
    </div>
  </div>
  )
}

export default ProjectItemMain