import React, { useState, useEffect } from 'react';
import { FaFacebookSquare, FaInstagram, FaTiktok} from 'react-icons/fa';
import { contact, footerItems } from '../../../data/data';
import FooterLinks from './FooterLinks';

function Footer() {
  const data = footerItems
  const item = contact
  
  // automaticly update year 
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
      setCurrentYear(new Date().getFullYear());
  }, []);


  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-row">
        <div className="footer-top">
        {data.map((e)=> (
        <div key={e.key} className="footer-col-2">
        <h1 className="footer-title">
          {e.title}
        </h1>
            <hr className="footer-hr"/>
            <ul className="footer-col-links">
              <li className="footer-col-link">
                {e.about}
              </li>
              <li className="footer-col-link">
                {e.services}
              </li>
              <li className="footer-col-link">
                {e.gallery}
              </li>
              <li className="footer-col-link">
                {e.news}
              </li>
              <li className="footer-col-link">
                {e.contact}
              </li>
            </ul>
          </div>
        ))}
        
        <div className="footer-col-4">
        <h1 className="footer-title title_countries">
          Η ΧΩΡΑ 
            <hr className="footer-hr hr-map"/>
        </h1>
          <img src='/images/iconServices/greece_map.png' className="footer-map" alt="" />
        </div>
        </div>

        <FooterLinks />
        
        </div>
        
        <div className="footer-media">
          <div className="media-left">
          © {currentYear} Master - By <a href="https://www.emougio.com" rel="noreferrer" className="emougio" target="_blank"> Emougio</a>
          </div>
          <div className="media-right">
            <ul className="media-links">
              <li className="media-facebook media">
                <a rel="noopener noreferrer" href={item.facebook} target="_blank" aria-label="Facebook">
                <FaFacebookSquare />
                </a>
              </li>
              <li className="media-tiktok media">
                <a rel="noopener noreferrer" href={item.tiktok} target="_blank" aria-label="TikTok">
                <FaTiktok />
                </a>
              </li>
              <li className="media-instagram media">
                <a rel="noopener noreferrer" href={item.instagram} target="_blank" aria-label="instagram">
                <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
</div>

  )
}

export default Footer