import React from 'react'
import { FaMapMarkerAlt, FaEnvelope, FaPhoneSquareAlt, FaFax } from 'react-icons/fa'

export const ContactSide = () => {



  return (
    
    <div className="rcol_container">
    <div className="rcol_title">
        <h1 className="contact_col">Επικοινωνία</h1>
        <hr className="map_col_hr"/>
    </div>
    <div className="rcol_details">
        <div className="rcol_items">
        <div className="triangle">
            <div className="rcol_item_l">
                <FaMapMarkerAlt/>
            </div>
                </div>
            <div className="rcol_item_r">
                
                <h1 className="rcol_item_r_title">
                Η εταιρεία 
                </h1>
                <p className="rcol_item_r_desc">
                <a href="https://www.google.gr/maps/place/Master+OE/@40.4927139,21.7122141,442m/data=!3m2!1e3!4b1!4m5!3m4!1s0x6216f43578856d5d:0x230cdf045cbc6319!8m2!3d40.4927119!4d21.7133084" target="_blank">
                5ο km Πτολεμαΐδας - Κοζάνης
                    </a> 
                </p>
            </div>
        </div>
        <div className="rcol_items">
        <div className="triangle">
            <div className="rcol_item_l">
                <FaEnvelope/>
            </div>
                </div>
            <div className="rcol_item_r">
                
                <h1 className="rcol_item_r_title">
                    Email 
                </h1>
                <p className="rcol_item_r_desc">
                <a href="mailto:masteroe2014@gmail.com">
                    masteroe2014@gmail.com
                </a>
                </p>
            </div>
        </div>
        <div className="rcol_items">
        <div className="triangle">
            <div className="rcol_item_l">
                <FaPhoneSquareAlt/>
            </div>
                </div>
            <div className="rcol_item_r">
                
                <h1 className="rcol_item_r_title">
                    Τηλέφωνο
                </h1>
                <p className="rcol_item_r_desc">
                    <a href="tel:+306976510926">
                    +306976510926
                    </a>
                </p>
            </div>
        </div>
        <div className="rcol_items">
        <div className="triangle">
            <div className="rcol_item_l">
                <FaFax/>
            </div>
                </div>
            <div className="rcol_item_r">
                
                <h1 className="rcol_item_r_title">
                    Σταθερό
                </h1>
                <p className="rcol_item_r_desc">
                <a href="tel:+306976510926">
                    +306976510926
                    </a>
                </p>
            </div>
        </div> 



        
    </div>
</div>
  )
}
