import React from 'react'
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt  } from 'react-icons/fa'

function LinkedPg(props) {
  
  return (
    <div className="company_pg-hd_header">
        <h2 className="company_pg-hd-title">
            < FaMapMarkerAlt className="company_pg-pin" />
                    <Link className="company_pg-hd-title-home" to="/">
                        ΑΡΧΙΚΗ
                    </Link>
                     <span className="span-company"> / {props.title}  </span> 
          </h2>
    </div>
  )
}

export default LinkedPg