import React from 'react'
import { footerLinks } from '../../../data/data'

function FooterLinks() {
    const data = footerLinks

  return (
    <div className="footer-bottom">
        {data.map((item) => (
    <div key={item.key}
    className="footer-bottom-col">
      <div className={item.class}>
        <div className={item.classNm}>
        <a className="footer-bottom-link" 
        rel="noopener noreferrer" 
        href={item.href} 
        target="_blank" 
        aria-label="Facebook">
        {item.icon}    
        </a>
        </div>
        <div className={item.bt_right}>
          <h1 className="bt-title">
            {item.title}
          </h1>
          <p className="bt-detail ">
            {item.desc}
            {/* {item.desc1} */}
            <br/>
            {item.desc2}
          </p>
          
        </div>
      </div>
    </div>
    ))}
  </div>
  )
}

export default FooterLinks