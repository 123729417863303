import React from 'react'

function WhyUsItem(props) {

    
  return (
      <div className="why-us-container">
    <ul className="why-us-items">
        {props.item.map((e) =>

        <li key = {e.key} 
        className="why-us-item our-company">
            <div className="why-us-hover">
                <div className="choose-border">
                    <h2 
                    className="why-choose">
                        {e.title}
                    </h2>
                </div>
            <h3 className="why-company-title">
                {e.header}
            </h3>
            </div>
                <hr />
                <p className="why-company why-left">
                    {e.para}
            </p>
        </li>

        )}
        
        {props.data.map((i) =>
        <li key = {i.key} 
        className={i.class}>
        <div className="why-us-hover">
            <h1 className="why-us-number">
                {i.title}
            </h1>
            <h3 className="why-title">
                {i.header}
            </h3>
        </div>
            <p className="why-company">
                {i.para}
            </p>
        </li>
        )}
    </ul>
</div>
  )
}

export default WhyUsItem