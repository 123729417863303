import React from 'react'
import ServicesColMain from './ServicesColMain'
import { servicemain, services } from '../../../data/data';
import ServiceItem from './ServiceItem';

function ServiceCol(props) {
  return (
    <>
    <div className="services-col-1">
    
                    <ServicesColMain 
                    servicemain = { servicemain }
                    />
                </div>
                <div className="services-col-2">
                   <ServiceItem 
                    serviceitem = { services }
                   />
                </div>
    </>
  )
}

export default ServiceCol