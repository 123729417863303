import React from 'react'

function PagesBg(props) {
  return (
    <div>
        <img src={props.e.img} className={props.e.className} alt="Page Loading.." />
    </div>
  )
}

export default PagesBg