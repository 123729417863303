import React from 'react'
import { PageBgHeader } from '../../../data/data'
import { mainblog } from '../Blog/BlogData'
import BlogItems from '../Blog/BlogItems'
// import PgAbout from '../Company/PgAbout'
import ContactSec from '../Contact/ContactSec'
import LinkedPg from '../PagesBg/LinkedPg'
import PagesBg from '../PagesBg/PagesBg'

function BlogPage() {
  return (
    <div className="services_page">
        <PagesBg 
        e = { PageBgHeader } />
        <div className="company_page-container p-top10">
            <h1 className="company_pg-title">
                ΤΟ BLOG ΜΑΣ
            </h1>
            <div className="company_page-header">
                <LinkedPg className="inline_flex"
                title = "BLOG" />
            </div>
            <div className="blog-container">
            <div className="blog-items">
                <div className="blog-items-container">
                    <BlogItems 
                    data = {mainblog} />
                </div>
                
            </div>
            </div>
            <ContactSec />
        </div>
    </div>
  )
}

export default BlogPage