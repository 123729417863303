import React, { useState } from 'react'

function ServiceItem(props) {

    const [ selected, setSelected] = useState(null)

    const toggle = (i) => {
        if (selected === i ) {
            return setSelected(null)
        }

        setSelected(i)
    }
    
  return (
    <>
    {props.serviceitem.map((item, i) =>
    <div>
    <div key={ item.key }
    onClick={() => toggle(i)}
    className="service-1">  
    <span className="service_plus">{ selected === i ? '-' : '+'}</span>    
    <div
    className="service-img">
    <img alt='service'
    src={item.image} 
    className="service-img-logo" />
        </div>
    <h3 
    className="service-title">
        {item.title}
        
    </h3>
    </div>
    <p     
    className={selected === i ? 'para_show' : 'service-para'}>
        {item.para}
    </p>
    

</div>
     )} 
</>
  )
}

export default ServiceItem