import React from 'react'
import { CompanyPg, PageBgHeader } from '../../../data/data';
import CompanyPgItems from '../Company/CompanyPgItems';
import PgAbout from '../Company/PgAbout';
import Gallery from '../Gallery/Gallery';
import LinkedPg from '../PagesBg/LinkedPg';
import PagesBg from '../PagesBg/PagesBg';
import CompanyPgTeam from './CompanyPgTeam';
// import company1 from '../assets/photoProjects/car-wash-2.jpeg';
// import Gallery from '../Components/Gallery';

function CompanyPage(props) {
  const data = CompanyPg
  return (
    <>
    <div className="company_page">
      <PagesBg
      e = { PageBgHeader } />
      <div className="company_page-container">
        <h1 className="company_pg-title">{data.title}</h1>
        <div className="company_page-header">
        
          <LinkedPg 
          title ="Η ΕΤΑΙΡΕΙΑ" />
        
        </div>
        <div className="company_pg-row">
       <PgAbout
       about = "ΣΧΕΤΙΚΑ ΜΕ"
       header = "MASTER OE" />
       <CompanyPgItems />
        </div>
      </div>
   <CompanyPgTeam />
    </div>
    <Gallery />
    </>
  )
}

export default CompanyPage