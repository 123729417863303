import { v4 as uuid }  from 'uuid'
import { FaStar } from 'react-icons/fa'


export const testimonialData = 

    {
        key: uuid(),
        clients:"ΟΙ ΠΕΛΑΤΕΣ ΜΑΣ",
        ratings:"Αξιολογήσεις"

    }


export const testimonialData2 =  [
        {
            key: uuid(),
            name:"Αναστάσιος Σκόντας ",
            image:"images/Testimonials/AnastasiosSkontas.png",
            job:"Ελεύθερος Επαγγελματίας",
            desc:" Άμεση ανταπόκριση και  εξυπηρέτηση,  φιλικό και έμπειρο  προσωπικό. Καθάρισαν την αποχέτευση μου και με την βοήθεια της κάμερας μπορέσαμε και βρήκαμε το πρόβλημα που αντιμετωπίζω εδώ και χρόνια. ",
            rating: <FaStar />
    
        },
    
        {
            key: uuid(),
            name:"Νικόλαος Μπουδαλής ",
            image:"images/Testimonials/NikolaosMpoudalis.png",
            job:"Μηχανικός",
            desc:" Ανταποκρίθηκαν άμεσα στο τηλεφώνημα μου και σε σύντομο χρονικό διάστημα ήρθαν στο χώρο μου. Το πρόβλημα μου λύθηκε άμεσα και με τη χρήση της κάμερας που έχει το συνεργείο βρέθηκε ο λόγος που δημιουργήθηκε το πρόβλημα . Γρήγορη εξυπηρέτηση και αποτελεσματικότητα ",
            rating: <FaStar />
    
        },
        {
            key: uuid(),
            name:"ΗΛΙΑΣ",
            image:"images/Testimonials/Elias.jpeg",
            job:"Ελεύθερος Επαγγελματίας",
            desc:" Μοναδικοί στο είδος τους! Άμεση ανταπόκριση στην βλάβη μας, οι πιο οικονομικοι στην περιοχη μας . Τους συνιστώ ανεπιφύλακτα! ",
            rating: <FaStar />
    
        }
    ]

