import React from 'react'
import './About.css'


function About(props) {

    function scrolldiv() {
        var elem = document.getElementById("ourservices");
        elem.scrollIntoView(({behavior: "smooth", inline: "nearest"}));
    }

  return (
    <div className="about">
    <div className="about-container">
        <div className="about-header-tx">
            <h1 className="header-tx">
                {props.data.leader}
            </h1>
            <p className="header-desc">
                {props.data.welcome}
            </p>
            <div className="btn-parent">
            <button className="header-btn" type="button"
            onClick={scrolldiv}
            >
                {props.data.viewmore}
            </button>
            </div>
        </div>
        <div className="background-video">
            <video loop autoPlay muted className="VideoBg" 
            src={props.data.videobg} 
            />
          
        </div>
    </div>
    </div>

  )
}

export default About