import { useEffect, useMemo, useState } from 'react'
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { TbLineDotted } from 'react-icons/tb'
import { catalog, contact } from '../../../data/data'
import { AiOutlineClose } from 'react-icons/ai'
import './GalleryImages.css'
import ProjectListLinks from '../Projects/ProjectListLinks'




const GalleryImages = () => {
  const galleryImages = catalog 
  const e = contact

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)


  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }




  const [workList, setWorkList] = useState([]);
  const [ selectedCategory, setSelectedCategory] = useState([]);


  // Default Value
  useEffect(()=> {
    setWorkList(galleryImages)
  },);


  function getFilteredList(){
    if (!selectedCategory) {
      return workList;
    }
    return workList.filter((item) => item.category === selectedCategory);
  }


const filteredList = useMemo(getFilteredList, [selectedCategory, workList]);

function handleCategoryChange(event) {
    setSelectedCategory(event.target.value)
    
  }
  
  const [itemsToShow, setItemsToShow] = useState(6);

  const showMore = () => {
    setItemsToShow(galleryImages.length)
  }
  const showless = () => {
    setItemsToShow(6)
}

// const line = data[Math.floor(Math.random()*data.length)]
  return (
    <div>

      {openModal && 
        <div className='sliderWrap'>
          <FaRegArrowAltCircleLeft className="btnPrev" onClick={prevSlide} />
          <FaRegArrowAltCircleRight className="btnNext" onClick={nextSlide} />
          <AiOutlineClose className="btnClose" onClick={handleCloseModal} />
          
          <div  className='fullScreenImage'>
            <img  src={galleryImages[slideNumber].img} alt='' />
            <div className="fullScreen_desc-conatiner">
              <h2 className="fullScreen_title">
                {galleryImages[slideNumber].h3}
              </h2>
                  <h4 className="slideContact"> ΕΠΙΚΟΙΝΩΝΗΣΕ ΤΩΡΑ </h4>
              <ul className="slider_contact">
                <li className="slider_list">
                <FaPhoneAlt />
                  <a className="slider_link" href={"tel:" + e.tel} > Τηλέφωνο</a>
                </li>
                
                <li className="slider_list">
                <FaEnvelope />
                <a className="slider_link" href={"mailto:" + e.email}> Email</a>
                </li>
              </ul>

              
            </div>
          {/* <br />
          Current slide number:  {slideNumber}
          <br />
          Total Slides: {galleryImages.length}
          <br /><br /> */}
          </div>
        </div>
      }

      {/* <div className='galleryWrap'> */}
      <div className='project-wrapper'>
        {
          galleryImages && galleryImages.slice(0,itemsToShow).map((slide, index ) => {
            return(
              <div className="project">
                {filteredList.map((element,index)=>(
                  <>
                  <GalleryImages {...element} key={index} />
                  <ProjectListLinks handle={handleCategoryChange} />
                  </>
                ))}
                <div className="project-image">
                
                  <figure key = {index} onClick = { () => handleOpenModal(index)}
                  className="figure-image">
                  <img src={slide.img} alt='' className="project-img-item" />
                  <div className="project-content">
                  <h3 className="project-title">
                    {slide.h3}
                  </h3>
                  <p className="content">
                    {slide.content}
                  </p>
                </div>
                  </figure>
                </div>
              </div>
            )
          })
          
        }
      </div>
        {(itemsToShow === 6)
        ?  <a onClick={showMore} className="view_more"> <TbLineDotted className="TbLineDotted"/> </a> 
        : <a onClick={showless} className="view_more">Show Less</a>
        }

    </div>
  )
}

export default GalleryImages