import React from 'react'
import ProjectItemHeader from './ProjectItemHeader'
import { projectitemheader } from '../../../data/data'
import ProjectItemMain from './ProjectItemMain'

function Projects() {
  return (
    <section id="latest-project" className="sppb-section title-4 title-w-55 sppb-section-content-center">
      <div className="sppb-row-container">
        <ProjectItemHeader 
        data = { projectitemheader }
        />
        <div className="projects-row row">
        <ProjectItemMain 
        // maindata = { pojectitemmain }
        />
        </div>
      </div>
    </section>
  )
}

export default Projects