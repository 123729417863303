import { v4 as uuid }  from 'uuid'

export const blogheader = 
[
    {
        key: uuid(),
        title:"BLOG",
        header:"ΤΑ ΝΕΑ ΜΑΣ",
        desc:""
    }
]

export const mainblog =
    [

    
        {
            key: uuid(),
            image:"/images/Blog/blockages_5.jpg",
            title:"Συμβουλές για τη σωστή συντήρηση των αποχετεύσεων.",
            header:"Αποχετευση",
            date:"23.10.2022",
            desc:"Πόσες φορές πανικοβληθήκαμε βλέποντας τη αποχέτευση μας να φράζει και ακάθαρτα νερά και λίματα να επιστρέφουν στο χώρο μας? Πόσες φορές αναρωτηθήκαμε γιατί η αποχέτευση μας μυρίζει έντονα? Η αποχέτευση, μπορεί να φαίνεται ότι λειτουργεί κανονικά, αλλά μπορεί  ανά πάσα στιγμή να φράξει. Η σωστή χρήση της αποχέτευσης και η τακτική συντήρηση μπορούν να αποτρέψουν εύκολα μια κατάσταση έκτακτης ανάγκης. Δυστυχώς κανένας ιδιοκτήτης ακινήτου δεν σκέφτεται την αποχέτευση του, μέχρι να παρουσιαστεί κάποιο πρόβλημα. Αυτό το άρθρο  στοχεύει στο να δοθούν απαντήσεις και λύσεις στα προβλήματα που αφορούν την αποχέτευση στο χώρο μας."
        },
        
        {
            key:uuid(),
            image:"/images/Blog/car-wash-7.jpeg",
            title:"Συντήρηση μηχανημάτων έργου.",
            header:"Συντήρηση",
            date:"24.10.2022"

        }
    
       
    
    ]