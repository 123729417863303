import React from 'react'
import { Link
    // useLocation, 
    // useParams 
} from 'react-router-dom';
import { linkNames } from '../../data/data';

function LinkItems(props) {

   
  return (
    
    <div>
        {linkNames.map((item) => (
        <li key={item.key}
        className="sidebar-menu-items" >
            <Link 
            onClick={props.closeMenu}
            className="sidebar-menu-item" 
            aria-current="page" 
            to={{
            pathname:item.to,
            // state: { stateParam: true }
            }}>
        {item.name}
        </Link>
    
    
    {/* react-jsx-dev-runtime.development.js:87 Warning: Failed prop type: The prop `to` is marked as required in `Link`, but its value is `undefined`. */}
    {/* <Link 
    className="" 
    aria-current="page" 
    to={{
        pathname:data.to,
        state: { stateParam: true }
        }
        }
        >
    {data.name}
    </Link> */}
</li>
))}
    </div>
  )
  
}

export default LinkItems