import React from 'react'

function CompanyCol2(props) {
  return (
    <>
    <div className="company-col">
    <div className="company-col-2-row">
        <img src={props.companycol2.seed} alt="enviroment" className="hand-tree" />
            <h1 className="addon-text2">
                    {props.companycol2.eco}
            </h1>

            <p className="addon-desc addon-desc2">{props.companycol2.certificate}</p>   
            </div>          
            </div>
              <div className="company-col">
              <img src={props.companycol2.excavator} alt="excavator" className="excavator" />
                          
              <h1 className="addon-text3">
              {props.companycol2.modernTech}
                  
              </h1>
              <p className="addon-desc">{props.companycol2.modernTechDesc}</p>
              
          </div>
    </>
  )
}

export default CompanyCol2