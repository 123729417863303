// import React from "react";
// import { 
//     // useJsApiLoader,
//     GoogleMap,
//     Marker,
//     InfoWindow,
//     // Data
//  } from '@react-google-maps/api'




// export const Map = () => {

// // const [selected, setselected] = useState(null);
// const center = { lat: 40.492848, lng: 21.713438 }
//   return (
    
//     <div>
//         <div className="contact-map-form-container">
//                     <div className="contact-map-row">
                        
//                     <GoogleMap 
//                     className="mapContainerStyle"
//                     center={center}
//                     zoom={16}
//                     mapContainerStyle={{
//                         width:'500px',
//                         height:'500px', 
//                         display:'inline-table'
//                     }}
//                     >
//                         <Marker 
//                         position={{
//                             lat:40.492749, 
//                             lng:21.713276,
//                         }}/>  
//                             <InfoWindow 

//                             position={{
//                                 lat:40.493361,  
//                                 lng: 21.713400 
//                             }}
//                             >
//                             <div className="pin_wrapper"  >
//                                 <h2 className="pin_title">Master OE </h2>
//                                 <p className="pin_desc">5ο km Πτολεμαΐδας - Κοζάνης</p>
//                                 <a href="https://www.google.gr/maps/place/Master+OE/@40.4927139,21.7122141,442m/data=!3m2!1e3!4b1!4m5!3m4!1s0x6216f43578856d5d:0x230cdf045cbc6319!8m2!3d40.4927119!4d21.7133084" rel="noreferrer" target="_blank">
//                                     View on Google Maps                                
//                                 </a>
//                             </div>
//                          </InfoWindow>                    
//                     </GoogleMap>
//                     </div>
//                     </div>
//     </div>
//   )
// }

import React from "react";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'

export const Map = () => {
  const center = { lat: 40.492848, lng: 21.713438 }

  return (
    <div>
      <div className="contact-map-form-container">
        <div className="contact-map-row">
        <script async src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY_HERE&callback=console.debug&libraries=maps,marker&v=beta">
    </script>
        <gmp-map center="40.492767333984375,21.713287353515625" zoom="14" map-id="DEMO_MAP_ID">
      <gmp-advanced-marker position="40.492767333984375,21.713287353515625" title="My location"></gmp-advanced-marker>
    </gmp-map>
          {/* <GoogleMap 
            style={{ // Change className to style
              width: '500px',
              height: '500px',
              display: 'inline-table'
            }}
            center={center}
            zoom={16}
            mapContainerStyle={{
              width: '500px',
              height: '500px',
              display: 'inline-table'
            }}
          >
            <Marker 
              position={{
                lat: 40.492749, 
                lng: 21.713276,
              }}
            />  
            <InfoWindow 
              position={{
                lat: 40.493361,  
                lng: 21.713400 
              }}
            >
              <div className="pin_wrapper"  >
                <h2 className="pin_title">Master OE </h2>
                <p className="pin_desc">5ο km Πτολεμαΐδας - Κοζάνης</p>
                <a href="https://www.google.gr/maps/place/Master+OE/@40.4927139,21.7122141,442m/data=!3m2!1e3!4b1!4m5!3m4!1s0x6216f43578856d5d:0x230cdf045cbc6319!8m2!3d40.4927119!4d21.7133084" rel="noreferrer" target="_blank">
                  View on Google Maps                                
                </a>
              </div>
            </InfoWindow>                    
          </GoogleMap> */}
        </div>
      </div>
    </div>
  )
}
