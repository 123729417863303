import React from 'react'
import { tech } from '../../../data/data'
import TechItems from './TechItems'

function Tech() {
  return (
    <div className="tech">
    <TechItems 
    data = { tech }
    />
</div>
  )
}

export default Tech