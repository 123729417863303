import React  from 'react'
import { catalog } from '../../../data/data';
import GalleryImages from './GalleryImages';
// import { TbLineDotted } from 'react-icons/tb'


function Gallery() {


//   const [itemsToShow, setItemsToShow] = useState(6);
//   const galleryImages  = catalog
//   const showMore = () => {
//     setItemsToShow(galleryImages.length)
//   }
//   const showless = () => {
//     setItemsToShow(9)
// }


  return (
    <div className="gallery">
      <div className="gallery-container bg-black">
        <div className="gallery-header">
          <div className="gallery-title-bg">
          <h2 className="gallery-title">
            Η ΔΟΥΛΕΙΑ ΜΑΣ
          </h2>
          </div>
          <h1 className="gallery-text">
            Η ΚΑΘΗΜΕΡΙΝΟΤΗΤΑ ΜΕΣΑ ΑΠΟ ΦΩΤΟΓΡΑΦΙΕΣ
          </h1>
          <hr className="member-hr"/>
          {/* <p className="gallery-desc">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
          </p> */}
        </div>

      <GalleryImages
        galleryImages={catalog}
        />
        {/* {(itemsToShow === 6)
        ?  <a onClick={showMore} className="view_more"> <TbLineDotted className="TbLineDotted"/> </a> 
        : <a onClick={showless} className="view_more">Show Less</a>
        } */}

      </div>
    </div>
  )
}

export default Gallery









